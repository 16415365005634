import React from "react";
import { isEmpty } from "lodash";

const Dashboard = ({ authData }) => {
  //console.log(authData)
  if (isEmpty(authData)) {
    //window.location.reload(true)
    return null;
  }

  const { user } = authData;

  return (
    <div className="card-body">
      <div className="">
        {!isEmpty(user.firstName) || !isEmpty(user.lastName) ? (
          <p>
            Witaj{" "}
            <strong>
              {user.firstName} {user.lastName}!
            </strong>
          </p>
        ) : (
          <p>
            Witaj <strong>{user.username}!</strong>
          </p>
        )}
        {user.b2b === "1" ? (
          <p>
            Gratulacje jesteś{" "}
            <strong className="text-success">klientem B2B!</strong>
            <br />
            <br />
            <p className="lead">
              {" "}
              Odblokowałeś nowe supermoce! Na przykład 25% ogólnego rabatu na
              koszyk.
              <br />
              <strong>
                Wprowadź kod kuponu: <b className="text-success">b2bclient</b>{" "}
                podczas realizacji transakcji, aby uzyskać zniżkę.
              </strong>
            </p>
          </p>
        ) : null}
        <section>
          Z pulpitu nawigacyjnego konta możesz przeglądać swoje ostatnie
          zamówienia, zobaczyć adres wysyłkowy i rozliczeniowy.
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
